// Polyfills come first
import 'react-app-polyfill/ie11';
import 'url-polyfill';
import 'unfetch/polyfill';
import 'core-js';

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV === "production") {
  const disableOnlineLogs = localStorage.getItem("disableOnlineLogs");
  if (disableOnlineLogs !== "true") {
    LogRocket.init("gcith1/consolesgqrfomopaynet");
    Sentry.init({ dsn: "https://6e8bb65a570148589082eef992b0b512@sentry.io/1301932" });
  }
}
ReactDOM.render(<App />, document.getElementById("root"));
