import { get } from "lodash-es";

export function assertAPIError(resBody) {
  if (resBody.error_code) throw new Error(resBody.error_message);

  if (((resBody.result_code / 100) | 0) === 4) {
    // 4xx
    const redirURL = get(resBody, ["data", "redirect"]);
    if (redirURL) {
      const currURL = new URL(window.location.href);
      if (!currURL.searchParams.get("fid")) {
        // has fid in QS, pending login
        window.location.replace(redirURL);
      }
    }
  }

  if (resBody.result_code) throw new Error(resBody.result_code);
}

export function assertResError(res) {}

export const postJson = (url, bodyObj, init = {}) => {
  const uaid = localStorage.getItem("uaid");
  let fetchInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    ...init
  };

  if (typeof bodyObj === "object") {
    fetchInit.body = JSON.stringify(bodyObj);
  }

  if (uaid) {
    fetchInit.headers["Authorization"] = `Bearer Uaid=${uaid}`;
  }

  return fetch(url, fetchInit);
};
