import React from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { get } from "lodash-es";
import withRoot from "../withRoot";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import KYCCreatePage from "../../routes/kyc/create";
import KYCListPage from "../../routes/kyc/list";
import KYCReviewPage from "../../routes/kyc/review";

import { Route, Link, Switch, withRouter } from "react-router-dom";
// import Docs from './routes/docs';
import Welcome from "../../routes/Welcome";
import NoMatch from "../../routes/NoMatch";

// import actions from './actions';

import actions from "../../redux/actions";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    zIndex: 1,
    overflow: "hidden",
    position: "absolute"
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%"
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    title: {
      flex: 1
    }
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  toolBarRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  drawerHeader: theme.mixins.toolbar,
  drawerPaper: {
    width: 250,
    height: "100%"
  },
  drawerDocked: {
    height: "100%"
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: "100%",
    padding: theme.spacing.unit * 3,
    // height: 'calc(100% - 56px)',
    // minHeight: '100%',
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      // height: 'calc(100% - 64px)',
      marginTop: 64
    },
    overflowY: "auto"
  },
  nestedListItem: {
    paddingLeft: theme.spacing.unit * 4
  },
  copyrightFooter: {
    textAlign: "center",
    margin: "1em 0"
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper
  }
});

const drawerLinks = {
  kyc: [
    {
      name: "Create a KYC document",
      href: "/kyc/create"
    },
    {
      name: "Review KYC documents",
      href: "/kyc/review"
    }
  ]
};

class App extends React.Component {
  state = {
    mobileOpen: false,
    userMenu: {
      anchorEl: null
    }
  };

  handleDrawerOpen = () => {
    this.setState({ mobileOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ mobileOpen: false });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleQueryServiceLogin = () => {
    this.props.dispatch(actions.serviceLogin.query);
  };

  handleRequestServiceLogin = () => {
    this.props.dispatch(actions.serviceLogin.login);
  };

  handleServiceLoginLogout = () => {
    this.props.dispatch(actions.serviceLogin.logout);
  };

  setUserMenuOpen = opened => e =>
    this.setState({
      userMenu: {
        anchorEl: opened ? e.currentTarget : null
      }
    });

  componentDidMount() {
    this.handleQueryServiceLogin();
  }

  render() {
    const { classes, theme, serviceLogin } = this.props;

    const drawer = (
      <div>
        <Divider />
        <List>
          <ListItem button component={Link} to={"/"} key={"/"}>
            <ListItemText primary="SGQR KYC" />
          </ListItem>
        </List>
        <Divider />
        <List subheader={<ListSubheader className={classes.listSubheader}>KYC</ListSubheader>}>
          {drawerLinks.kyc.map(elem => (
            <ListItem button component={Link} to={elem.href} onClick={this.handleDrawerClose} key={elem.href}>
              {elem.icon ? <ListItemIcon>{elem.icon}</ListItemIcon> : null}
              <ListItemText primary={elem.name} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List subheader={<ListSubheader className={classes.listSubheader}>External Links</ListSubheader>}>
          <ListItem button component="a" href="https://accounts.fomopay.com/myaccount">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="My Account" />
          </ListItem>
        </List>
        <Divider />
        <Typography variant="caption" className={classes.copyrightFooter}>
          Copyright © 2014-2018 FOMO Pay
        </Typography>
      </div>
    );

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              {serviceLogin.status === "success" && (
                <IconButton color="inherit" aria-label="open drawer" onClick={this.handleDrawerOpen}>
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="title" color="inherit" noWrap style={{ flex: 1 }}>
                FOMO Pay SGQR KYC
              </Typography>
              {serviceLogin.status === "success" ? (
                <div className={classes.toolBarRight}>
                  <Typography variant="subheading" color="inherit" noWrap>
                    {get(serviceLogin, "userinfo.username") || get(serviceLogin, "userinfo.email")}
                  </Typography>
                  <IconButton
                    aria-owns={this.state.userMenu.anchorEl ? "menu-usericon-appbar" : null}
                    aria-haspopup="true"
                    onClick={this.setUserMenuOpen(true)}
                    color="inherit"
                  >
                    <AccountCircleIcon />
                  </IconButton>
                  <Menu
                    id="menu-usericon-appbar"
                    anchorEl={this.state.userMenu.anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                    }}
                    open={Boolean(this.state.userMenu.anchorEl)}
                    onClose={this.setUserMenuOpen(false)}
                  >
                    <MenuItem onClick={this.handleServiceLoginLogout}>Log Out</MenuItem>
                  </Menu>
                </div>
              ) : (
                <Button color="inherit" onClick={this.handleRequestServiceLogin}>
                  Login
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <React.Fragment>
            {serviceLogin.status === "success" && (
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={this.state.mobileOpen}
                classes={{
                  paper: classes.drawerPaper,
                  docked: classes.drawerDocked
                }}
                onClose={this.handleDrawerClose}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            )}
          </React.Fragment>
          <main className={classes.content}>
            <Switch>
              <Route path="/" exact component={Welcome} />
              <Route path="/kyc/create" exact component={KYCCreatePage} />
              <Route path="/kyc/review" exact component={KYCListPage} />
              <Route path="/kyc/review/:id" component={KYCReviewPage} />
              <Route component={NoMatch} />
            </Switch>
          </main>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  router: state.router,
  serviceLogin: state.serviceLogin,
  metadata: state.meta
});

export default withRoot(withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(App))));
