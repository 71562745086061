import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { debounce, clone, set, get } from "lodash-es";
import { Typography } from "@material-ui/core";
import uuid from "uuid/v1";

const styles = theme => ({
  sgqrAddrPaper: {
    padding: theme.spacing.unit * 2,
    margin: "1em 0"
  },
  inputField: {
    margin: "0 1em 0 0"
  },
  outletHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  terminalPaper: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

class AddressInput extends React.PureComponent {
  state = {
    suggestions: [],
    address: "",
    addressAutoFilled: false,
    level_no: "",
    misc: "",
    name_on_label: "",
    post_code: "",
    sgqr_id_exist: false,
    sgqr_id: "",
    unit_no: "",
    fetching: false
  };

  fetchSuggestions = async value => {
    const queryAPI = new URL(`https://www.onemap.gov.sg/api/common/elastic/search?&returnGeom=Y&getAddrDetails=Y&pageNum=1`);
    // console.log(queryAPI);
    this.setState({ fetching: true });
    queryAPI.searchParams.set("searchVal", value);
    let queryRes = await fetch(queryAPI);
    let queryData = await queryRes.json();
    this.setState({ fetching: false });
    // console.log(queryData);
    let queryResultsWithPostcode = queryData.results
      .filter(elem => typeof elem.POSTAL === "string")
      .map(elem => {
        if (elem.POSTAL.length >= 6) {
          return elem;
        }
        elem.POSTAL = `000000${elem.POSTAL}`.slice(-6);
        return elem;
      });
    // Onemap now seems responds POSTAL code without leading zeros.
    // console.log(queryResultsWithPostcode);
    this.setState({ value: value, suggestions: queryResultsWithPostcode });
  };

  fetchSuggestionsDebounced = debounce(this.fetchSuggestions, 1000);

  handleSuggestionClick = idx => () => {
    const addrInfo = this.state.suggestions[idx];
    this.setState({
      addressAutoFilled: true,
      suggestions: []
    });
    this.props.setFieldValue({
      ...this.props.value,
      address: addrInfo.ADDRESS,
      post_code: addrInfo.POSTAL
    });
  };

  handleAddressInput = e => {
    let value = e.target.value;
    if (this.state.addressAutoFilled) value = "";
    this.setState({
      // address: value,
      // post_code: "",
      addressAutoFilled: false,
      suggestions: []
    });
    this.props.setFieldValue({
      ...this.props.value,
      address: value,
      post_code: ""
      // addressAutoFilled: false
    });
    if (value) this.fetchSuggestionsDebounced(value);
  };

  linkInputState = key => e => {
    const t = e && e.target;
    const value = t.type.match(/^che|rad/) ? t.checked : t.value;
    // this.setState({
    //     [key]: value
    // });
    let newFieldValue = clone(this.props.value);
    set(newFieldValue, key, value);
    this.props.setFieldValue(newFieldValue);
  };

  handleAddCounter = () => {
    this.props.setFieldValue({
      ...this.props.value,
      terminals: [
        ...this.props.value.terminals,
        {
          name_on_label: this.props.value.terminals.slice(-1)[0].name_on_label || "",
          sgqr_id_exist: false,
          sgqr_id: "",
          misc: ("0000" + this.props.value.terminals.length).slice(-4),
          key: uuid()
        }
      ]
    });
  };

  handleRemoveCounter = idx_rm => () => {
    if (this.props.value.terminals.length === 1) return;
    // keep at least one terminal
    let terminals = this.props.value.terminals.filter((elem, idx) => idx !== idx_rm);
    // console.log('Counters:',terminals);
    this.props.setFieldValue({
      ...this.props.value,
      terminals
    });
  };

  render() {
    const { classes, value, error } = this.props;
    return (
      <Paper elevation={2} className={classes.sgqrAddrPaper}>
        <Grid container spacing={8}>
          <Grid item xs={12} className={classes.outletHeader}>
            <Typography variant="headline">Outlet {this.props.index + 1}</Typography>
            <Tooltip title="Remove Outlet">
              <Button size="small" onClick={this.props.onRemoveThis}>
                <Icon>delete_forever</Icon>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Postcode"
              value={value.address}
              onInput={this.handleAddressInput}
              inputProps={
                { autoComplete: "disabled" } // disable system autocomplete
              }
              onPaste={e => e.preventDefault()}
              fullWidth
              placeholder="Enter the postcode to find your outlet address."
              error={!!(error.address || error.post_code)}
              helperText={
                !!(error.address || error.post_code)
                  ? "Invalid address. Please enter a correct postcode and select a full address from the list."
                  : "Enter the postcode and select a full address."
              }
            />
            {this.state.fetching && <LinearProgress variant="query" />}
            <Paper square>
              {this.state.suggestions.map((elem, idx) => (
                <MenuItem key={elem.ADDRESS} onClick={this.handleSuggestionClick(idx)} component="div">
                  {elem.ADDRESS}
                </MenuItem>
              ))}
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label="Postcode"
                            value={this.state.post_code}
                            onInput={linkState(this, "post_code")}
                            inputProps={
                                { readOnly: true, autoComplete: "disabled" } // disable system autocomplete
                            }
                            placeholder="000000"
                            helperText="To be auto-filled with the address above."
                        />
                    </Grid> */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Level Number"
              value={value.level_no}
              onInput={this.linkInputState("level_no")}
              inputProps={
                {
                  minLength: "2",
                  maxLength: "3",
                  type: "text",
                  pattern: "[a-zA-Z0-9]+",
                  autoComplete: "disabled"
                } // disable system autocomplete
              }
              error={!!error.level_no}
              helperText={error.level_no || "2-3 alphanumeric chars, prepend 0 before single digit. ex: 01, B1"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="Unit Number"
              value={value.unit_no}
              onInput={this.linkInputState("unit_no")}
              inputProps={
                {
                  minLength: "2",
                  maxLength: "5",
                  type: "text",
                  pattern: "[a-zA-Z0-9]+",
                  autoComplete: "disabled"
                } // disable system autocomplete
              }
              error={!!error.unit_no}
              helperText={error.unit_no || "2-5 alphanumeric chars, prepend 0 before single digit. ex: 06, 13, 165A"}
            />
          </Grid>
          <Grid item xs={12} container spacing={8}>
            {value.terminals.map((elem, idx) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={elem.key}>
                <Paper elevation={2} className={classes.terminalPaper}>
                  <Grid item xs={12} className={classes.outletHeader}>
                    <Typography variant="headline">Counter {idx + 1}</Typography>
                    <Tooltip title="Remove Counter">
                      <Button size="small" onClick={this.handleRemoveCounter(idx)}>
                        <Icon>delete_forever</Icon>
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Name on label"
                      value={elem.name_on_label}
                      onInput={this.linkInputState(["terminals", idx, "name_on_label"])}
                      inputProps={{
                        maxLength: "25",
                        autoComplete: "disabled" // disable system autocomplete
                      }}
                      fullWidth
                      placeholder="To be shown on SGQR label"
                      error={!!get(error, ["terminals", idx, "name_on_label"])}
                      helperText={
                        get(error, ["terminals", idx, "name_on_label"]) ||
                        "Name of Merchant that will be printed on the SGQR Label. Up to 25 characters."
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={elem.sgqr_id_exist}
                          onChange={this.linkInputState(["terminals", idx, "sgqr_id_exist"])}
                          value="sgqr_id_exist"
                        />
                      }
                      label={"I have an existing SGQR"}
                    />
                  </Grid>
                  {elem.sgqr_id_exist && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        className={classes.inputField}
                        label="SGQR ID"
                        value={elem.sgqr_id}
                        onInput={this.linkInputState(["terminals", idx, "sgqr_id"])}
                        disabled={!elem.sgqr_id_exist}
                        autoComplete="disabled"
                        autoCapitalize="true"
                        placeholder="PLEASE DOUBLE CHECK"
                        error={!!get(error, ["terminals", idx, "sgqr_id"])}
                        helperText={
                          get(error, ["terminals", idx, "sgqr_id"]) ||
                          "In the form of YYMMDD<6 Hex digits>, mandatory if this SGQR label already exists. Ex: 180901A1B2C3"
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Misc"
                      value={elem.misc}
                      onInput={this.linkInputState(["terminals", idx, "misc"])}
                      inputProps={
                        {
                          maxLength: "10",
                          autoComplete: "disabled"
                        } // disable system autocomplete
                      }
                      error={!!get(error, ["terminals", idx, "misc"])}
                      helperText={
                        get(error, ["terminals", idx, "misc"]) ||
                        "Up to 10 chars, used to differ different counters. ex: 0000, 0001"
                      }
                    />
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            {typeof error.terminals === "string" && <FormHelperText error>{error.terminals}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <Button onClick={this.handleAddCounter} variant="outlined" color="primary" size="small">
              Add a counter
              <Icon>add</Icon>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(AddressInput);
