import React from "react";
import Paper from "@material-ui/core/Paper";
import Dropzone from "react-dropzone";
// import config from "../config";
import FileChip from "./FileChip";
import PropTypes from "prop-types";

const styles = {
  dropzoneBoxStyle: {
    minWidth: "100px",
    minHeight: "100px",
    height: "auto",
    width: "auto",
    padding: "10px"
  },
  warningTextStyle: {
    color: "red"
  }
};

export default class DropzoneFieldFormik extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.object),
    label: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    accept: PropTypes.string,
    setFieldValue: PropTypes.func
  };

  static defaultProps = {
    label: "",
    hintText: "",
    errorText: "",
    value: [],
    maxFileSize: 5 * 1024 * 1024 //5MB
  };

  /**
   * @async
   * @param {File[]} files
   * @return {undefined}
   */
  handleDropzoneDrop = async files => {
    let value = this.props.value;

    let filteredFiles = files.filter(file => file.size < this.props.maxFileSize);

    // for (let file of files) {
    //   let form_data = new FormData();
    //   form_data.append("attachment", file);
    //   let init = {
    //     credentials: "same-origin",
    //     method: "POST",
    //     body: form_data
    //   };
    //   let response = await fetch(`${config.API_BASE}/attachments`, init);
    //   if (Math.floor(response.status / 100) === 4) {
    //     console.warn("Unauthorized session. Please refresh to reauthorize.");
    //     // window.location.replace(config.AUTH_LOGIN);
    //     return;
    //   }
    //   let result = await response.json();
    //   res_arr.push(result);
    // }
    let newValue = [...value, ...filteredFiles];
    console.log(newValue);
    this.props.setFieldValue(this.props.id, newValue);
  };

  handleDropzoneDelete = file_id => e => {
    let newValue = this.props.value.filter(file => !(file.preview === file_id || file.id === file_id));
    this.props.setFieldValue(this.props.id, newValue);
  };

  render() {
    // console.log('rendering DropzoneField', this);
    let value = this.props.value;
    return (
      <div className="dropzone">
        <h4>{this.props.label}</h4>
        <p>{this.props.hint}</p>
        <Paper>
          <Dropzone accept={this.props.accept} style={styles.dropzoneBoxStyle} onDrop={this.handleDropzoneDrop}>
            <p>Click here or drop file to upload.</p>
            <p>
              Only images and pdfs are accepted.
              <br />
              Limit 5MB each file.
            </p>
            <p style={styles.warningTextStyle}>{this.props.errorText}</p>
          </Dropzone>
        </Paper>
        <aside>
          {value.map(file => (
            <FileChip
              key={file.preview || file.id}
              file={file}
              href={file.preview}
              clickable
              onRequestDelete={this.handleDropzoneDelete(file.preview || file.id)}
            />
          ))}
        </aside>
      </div>
    );
  }
}
