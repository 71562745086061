import React from "react";

import MarkdownDoc from "../components/MarkdownDoc";

/* eslint import/no-webpack-loader-syntax: off */
import welcomeMD from "!raw-loader!./welcome.md";

export default function(props) {
  return <MarkdownDoc source={welcomeMD} />;
}
