import React from "react";
import { Link } from "react-router-dom";

const isExternalLink = function(url) {
  return url.indexOf(":") > -1 || url.indexOf("//") > -1;
};

/**
 *
 * @param {string} href
 */
// function linkType(href){
//     if(href.startsWith('/'))
// }

/**
 * @param { React.ClassAttributes<{href: string, children: React.ReactNode}> } props
 * @returns { React.SFC }
 */
const LinkRenderer = props => {
  // console.log(props)
  if (isExternalLink(props.href)) {
    return <a href={props.href}>{props.children}</a>;
  } else {
    return <Link to={props.href}>{props.children}</Link>;
  }
};

export default LinkRenderer;
