import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const styles = {
  tablePaper: {
    overflow: "auto"
  }
};

const TableRenderer = props => (
  <Paper className={props.classes.tablePaper}>
    <Table>{props.children}</Table>
  </Paper>
);

const TableHeadRenderer = props => <TableHead>{props.children}</TableHead>;

const TableBodyRenderer = props => <TableBody>{props.children}</TableBody>;

const TableRowRenderer = props => <TableRow>{props.children}</TableRow>;

const TableCellRenderer = props => <TableCell padding="dense">{props.children}</TableCell>;

export default {
  Table: withStyles(styles)(TableRenderer),
  TableBody: TableBodyRenderer,
  TableHead: TableHeadRenderer,
  TableRow: TableRowRenderer,
  TableCell: TableCellRenderer
};
