import React from "react";
import Chip from "@material-ui/core/Chip";
import saveAs from "file-saver";

import config from "../config";

const styles = {
  fileChip: {
    margin: "5px"
  }
};

const openLinkNew = href => e => window.open(href);

const downloadByID = (fileId, fileName) => async e => {
  const uaid = localStorage.getItem("uaid");
  const imageFetchAPI = new URL("kyc/console/image/fetch", config.apiBase);

  let fetchInit = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer Uaid=${uaid}`
    },
    body: JSON.stringify({
      file_id: fileId
    })
  };

  let res = await fetch(imageFetchAPI, fetchInit);
  console.log(res);
  let resBlob = await res.blob();

  console.log(resBlob, fileName);
  saveAs(resBlob, fileName);
};

export default function FileChip({ onRequestDelete, file, href, ...rest }) {
  return (
    <Chip
      onDelete={onRequestDelete}
      style={styles.fileChip}
      label={file.name}
      onClick={rest.clickable && ((href && openLinkNew(href)) || (file.id && downloadByID(file.id, file.name)))}
      {...rest}
    />
  );
}
