export default {
  fontFamily: "Roboto, sans-serif",
  borderRadius: 2,
  palette: {
    primary: {
      light: "#ff5c4b",
      main: "#D42021",
      dark: "#9a0000"
    },
    secondary: {
      light: "#ff5c4b",
      main: "#D42021",
      dark: "#9a0000"
    }
  }
};
