import React from "react";
import Typography from "@material-ui/core/Typography";

export default function(props) {
  return (
    <div>
      <Typography align="center" variant="display4">
        404
      </Typography>
      <Typography align="center" variant="display2">
        Not Found
      </Typography>
      <Typography align="center" variant="headline">
        It seems your requested page is not found.
        <br />
        Would you like to try out links from the drawer menu on the left?
      </Typography>
    </div>
  );
}
