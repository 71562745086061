import React from "react";
import Paper from "@material-ui/core/Paper";
import SyntaxHighlighter from "react-syntax-highlighter/prism";
import { okaida } from "react-syntax-highlighter/styles/prism";

const CodeRenderer = props => (
  <Paper>
    <SyntaxHighlighter language={props.language} style={okaida}>
      {props.value}
    </SyntaxHighlighter>
  </Paper>
);

export default CodeRenderer;
