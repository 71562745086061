import * as yup from "yup";

import validateUEN from "../../utils/validateUEN";
import validateNRIC from "../../utils/validateNRIC";

const regexAlphanumeric = /^[\w]*$/;
const msgAlphanumeric = "This field may only contain 0-9 and a-z.";

const regexNumeric = /^[\d]*$/;
const msgNumeric = "This field may only contain 0-9.";

const regexSGMobileNumber = /^[8|9]\d{7}$/i;
const msgSGMobileNumber = "Invalid Singaporean phone number.";

const regexAlphanumericSpecial = /^[\w .\-[\]{}]*$/;
const msgAlphanumericSpecial = "This field may only contain 0-9, a-z and some special characters.";

const regexSGQRID = /^(\d{2})(0[\d]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([0-9a-fA-F]{6})$/; // YYMMDD<6 Hex digits>
const msgSGQRID =
  "SGQR ID should be in the format of YYMMDD<6 Hex digits>, where YYMMDD is year, month, date, and hex digits are 0-9 and A-F. ex: 180901ABC123.";

const regexURL = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
const msgURL = "Invalid URL format";

const regexBankAccountNumber = /^[\d-]+$/;
const msgBankAccountNumber = 'Bank account number may only include numbers and "-".';

const msgUEN = "Invalid UEN format.";

const regexMCC = /^\d{4}$/;
const msgMCC = "MCC must be 4 digits.";

const msgNRIC = "Invalid NRIC format.";

const msgQrTypes = "You must choose at least 1 QR type."

const SGQRKYCSchema = yup.object().shape({
  mobile_country: yup
    .string()
    .required("Required")
    .max(3)
    .matches(regexNumeric, msgNumeric)
    .label("Country Code"),
  mobile_number: yup
    .string()
    .required("Required")
    .max(12)
    .when("mobile_country", {
      is: val => val === "65",
      then: yup.string().matches(regexSGMobileNumber, msgSGMobileNumber),
      else: yup.string().matches(regexNumeric, msgNumeric)
    })
    .label("Mobile Number"),
  schemes: yup
    .array()
    .required()
    .default(["PAYNOW", "GRABPAY", "DASH", "WECHATPAY", "REMO", "UNIONPAY15", "EZLINK", "CARD", "NETSPAY", "ALIPAY", "SHOPEEPAY", "ATOME"])
    .label("Schemes"),
  company_register_name: yup
    .string()
    .required()
    .label("Company register name"),
  merchant_trading_name: yup
    .string()
    .required()
    .label("Merchant trading name"),
  uen_number: yup
    .string()
    .required()
    .test("validate-uen", msgUEN, validateUEN)
    .label("Company UEN number"),
  company_website: yup
    .string()
    .matches(regexURL, msgURL)
    .label("Company Website"),
  mcc: yup
    .string()
    .required()
    .label("Merchant Category Code"),
  mcc_other: yup
    .string()
    .label("Merchant Category Code")
    .when("mcc", {
      is: "OTHR",
      then: yup
        .string()
        .matches(regexMCC, msgMCC)
        .required()
    }),
  registered_address: yup
    .string()
    .required()
    .label("Register address"),
  outlets: yup
    .array()
    .required()
    .of(
      yup.object({
        address: yup
          .string()
          .required()
          .label("Address"),
        level_no: yup
          .string()
          .default("00")
          .min(2, "Prepend 0 before single digit. ex: 01")
          .max(3)
          .required("Required, fill 00 if not appliable.")
          .matches(regexAlphanumeric, msgAlphanumeric)
          .label("Level number"),
        post_code: yup
          .string()
          .required()
          .min(6)
          .max(25)
          .matches(regexNumeric, msgNumeric)
          .label("Postcode"),
        unit_no: yup
          .string()
          .default("00")
          .min(2, "Prepend 0 before single digit. ex: 01")
          .max(5)
          .required("Required, fill 00 if not appliable.")
          .matches(regexAlphanumeric, msgAlphanumeric)
          .label("Unit number"),
        terminals: yup
          .array()
          .required()
          .of(
            yup.object({
              name_on_label: yup
                .string()
                .required()
                .max(25)
                .matches(regexAlphanumericSpecial, msgAlphanumericSpecial)
                .label("Name on label"),
              sgqr_id_exist: yup.boolean().default(false),
              sgqr_id: yup
                .string()
                .when("sgqr_id_exist", {
                  is: true,
                  then: yup
                    .string()
                    .required()
                    .matches(regexSGQRID, msgSGQRID)
                })
                .label("SGQR ID"),
              misc: yup
                .string()
                .matches(regexAlphanumericSpecial, msgAlphanumericSpecial)
                .max(10)
                .when("sgqr_id_exist", {
                  is: true,
                  then: yup.string().required("Required, an unique label to differ counters.")
                })
                .label("Misc")
            })
          )
          .test("misc-dedup", "Misc cannot be duplicated within one outlet.", value => {
            let miscs = value.map(elem => elem.misc).filter(misc => !!misc);
            // console.log(miscs);
            return miscs.length === new Set(miscs).size;
          })
      })
    )
    .test("address-dedup", "Outlet addresses (postcode, level number, unit number) cannot be duplicated.", value => {
      let arrOutletAddresses = value.map(outlet => `${outlet.post_code}${outlet.level_no}${outlet.unit_no}`);
      return new Set(arrOutletAddresses).size === arrOutletAddresses.length;
    })
    .test("sgqrid-dedup", "SGQRID cannot be duplicated.", value => {
      /**
       * value: outlets
       * [
       *  {
       *       terminals:
       *      [
       *          {
       *              sgqr_id: String
       *          }
       *      ]
       *  }
       * ]
       *
       */
      let arrTerminals = value.reduce((accu, elem) => [...accu, ...elem.terminals], []);
      // console.log("arrTerminals:", arrTerminals);
      let arrSGQRIDs = arrTerminals.reduce((accu, elem) => [...accu, elem.sgqr_id], []);
      // console.log("arrSGQRIDs:", arrSGQRIDs);
      let arrSGQRIdsValid = arrSGQRIDs.filter(val => !!val); // filter out empty sgqrids
      // console.log("arrSGQRIdsValid:", arrSGQRIdsValid);
      return arrSGQRIdsValid.length === new Set(arrSGQRIdsValid).size;
    }),
  full_name: yup
    .string()
    .required()
    .label("Full name"),
  contact_number: yup
    .string()
    .required()
    .label("Contact number"),
  nric_number: yup
    .string()
    .required()
    .test("validate-nric", msgNRIC, validateNRIC)
    .label("NRIC/ID number"),
  home_address: yup.string().label("Home address"),
  email_address: yup
    .string()
    .email()
    .required()
    .label("Email address"),
  account_name: yup
    .string()
    .required()
    .label("Account name"),
  account_number: yup
    .string()
    .matches(regexBankAccountNumber, msgBankAccountNumber)
    .required()
    .label("Account number"),
  bank_name: yup
    .string()
    .required()
    .label("Bank name"),
  bank_name_other: yup
    .string()
    .when("bank_name", {
      is: "other",
      then: yup.string().required()
    })
    .label("Bank name"),
  referrer: yup.string().label("Referrer"),
  agent: yup.string().label("Agent"),
  review_action: yup.string().label("Review action"),
  service: yup
    .string()
    .when("review_action", {
      is: review_action => ["approve", "reject"].includes(review_action),
      then: yup.string().required()
    })
    .label("Service type"),
  recaptcha_required: yup.boolean(),
  recaptcha_token: yup
    .mixed()
    .when('recaptcha_required',{
      is: true,
      then:yup.string().required()
    })
    .label("Recaptcha"),
  qr_types: yup.array().notRequired().when("review_action", {
      is: review_action => ["approve", "reject"].includes(review_action),
      then: yup.array().min(1, msgQrTypes)
    }
  )
});

export default SGQRKYCSchema;
