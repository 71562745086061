import React from "react";
import ReactMarkdown from "react-markdown";
import { withStyles } from "@material-ui/core/styles";
import TableRenderers from "./TableRenderers";
import CodeRenderer from "./CodeRenderer";
import LinkRenderer from "./LinkRenderer";

const stylesGen = theme => ({
  markdown: {
    ...theme.typography.body1,
    "& h1": theme.typography.display1,
    "& h2": theme.typography.headline,
    "& h3": theme.typography.title,
    "& h4": theme.typography.subheading,
    "& code": {
      fontFamily: '"Roboto Mono", monospace'
    }
  }
});

class MarkdownDoc extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <ReactMarkdown
        className={this.props.classes.markdown}
        source={this.props.source}
        unwrapDisallowed
        renderers={{
          table: TableRenderers.Table,
          tableHead: TableRenderers.TableHead,
          tableBody: TableRenderers.TableBody,
          tableRow: TableRenderers.TableRow,
          tableCell: TableRenderers.TableCell,
          code: CodeRenderer,
          link: LinkRenderer
        }}
      />
    );
  }
}

export default withStyles(stylesGen)(MarkdownDoc);
