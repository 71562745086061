import React from "react";
import { withStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/TablePaginationActions";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import config from "../../config";
import { assertAPIError, postJson } from "../../utils/apiClient";
import linkState from "linkstate";
import { debounce } from "lodash-es";

const styles = theme => ({
  mainPaper: {
    padding: theme.spacing.unit * 4
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableFilter: {
    padding: "16px"
  }
});

// const initialValues = SGQRKYCSchema.cast();

const filterStatusOptions = {
  applied: "Applied",
  approved: "Approved",
  reviewed: "Reviewed",
  approve_rejected: "Approval Rejected",
  review_rejected: "Review Rejected"
};

class SGQRKYCList extends React.PureComponent {
  state = {
    listData: [],
    sort: ["mid", "ASC"],
    filters: {
      status: []
    },
    keyword: "",
    page: 0,
    rowsPerPage: 10,
    dataCount: 0
  };

  loadKYCList = async () => {
    try {
      let { page, rowsPerPage, keyword } = this.state;

      let KYCListURL = new URL("kyc/console/list", config.apiBase);
      let KYCListRes = await postJson(KYCListURL, {
        status: this.state.filters.status,
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        keyword
      });
      console.log(KYCListRes);
      let contentRange = KYCListRes.headers.get("content-range");
      let KYCListBody = await KYCListRes.json();
      // let KYCListBody = mockKYCListData;
      console.log(KYCListBody);
      assertAPIError(KYCListBody);
      let dataCount = parseInt(contentRange.split("/").pop());
      this.setState({
        listData: KYCListBody.data,
        dataCount
      });
    } catch (err) {
      console.error(err);
    }
  };

  loadKYCListDebounced = debounce(this.loadKYCList, 1000);

  handleChangePage = (event, page) => {
    this.setState({ page });
    // this.loadKYCList();
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    // this.loadKYCList();
  };

  handleFiltersStatusChange = event => {
    this.setState({
      filters: {
        ...this.state.filters,
        status: event.target.value
      }
    });
  };

  componentDidMount() {
    this.loadKYCList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.page !== this.state.page ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.filters !== this.state.filters ||
      prevState.keyword !== this.state.keyword
    ) {
      this.loadKYCListDebounced();
    }
  }

  render = () => {
    let tableBody = null;
    const { rowsPerPage, page, dataCount } = this.state;

    if (this.state.listData.length === 0) {
      tableBody = (
        <TableRow>
          <TableCell rowSpan={64}>(no data)</TableCell>
        </TableRow>
      );
    } else {
      tableBody = this.state.listData.map(row => (
        <TableRow key={row.application_id}>
          <TableCell>{row.application_id}</TableCell>
          <TableCell>{row.uen}</TableCell>
          <TableCell>{row.registered_name}</TableCell>
          <TableCell>{row.mobile_no}</TableCell>
          <TableCell>{row.referer}</TableCell>
          <TableCell>{(row.status || "").toUpperCase()}</TableCell>
          <TableCell>
            <IconButton color="primary" component="a" href={`/kyc/review/${row.application_id}`}>
              <Icon>edit</Icon>
            </IconButton>
          </TableCell>
        </TableRow>
      ));
    }

    return (
      <Paper elevation={2} className={this.props.classes.mainPaper}>
        <h1>SGQR Review List</h1>
        <p>Review SGQR KYC info here, and submit for code payload generation.</p>
        <Paper className={this.props.classes.tableRoot}>
          <div className={this.props.classes.tableFilter}>
            <FormControl style={{ minWidth: "12ex" }}>
              <InputLabel htmlFor="select-multiple-checkbox">Status</InputLabel>
              <Select
                multiple
                value={this.state.filters.status}
                onChange={this.handleFiltersStatusChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected => selected.map(key => filterStatusOptions[key]).join(", ")}
              >
                {Object.entries(filterStatusOptions).map(([key, name]) => (
                  <MenuItem key={key} value={key}>
                    <Checkbox checked={this.state.filters.status.includes(key)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            &nbsp;
            <FormControl>
              <InputLabel htmlFor="select-multiple-checkbox">Search</InputLabel>
              <Input
                id="input-keyword-search"
                startAdornment={
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                }
                value={this.state.keyword}
                onChange={linkState(this, "keyword")}
              />
            </FormControl>
          </div>
          <Table className={this.props.classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Application ID</TableCell>
                <TableCell>UEN</TableCell>
                <TableCell>Company Register Name</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Referrer</TableCell>
                <TableCell>Review Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={dataCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Paper>
    );
  };
}

export default withStyles(styles)(SGQRKYCList);
