import config from "../../config";
import history from "../../components/browserHistory";
import { postJson, assertAPIError } from "../../utils/apiClient";
import { get } from "lodash-es";
export const SERVICELOGIN_QUERY = "SERVICELOGIN_QUERY";
export const SERVICELOGIN_LOGIN = "SERVICELOGIN_LOGIN";
export const SERVICELOGIN_LOGOUT = "SERVICELOGIN_LOGOUT";

const SERVICELOGIN_AUTH_API = "servicelogin/auth";
const SERVICELOGIN_QUERY_API = "kyc/console/userinfo";

const SERVICELOGIN_AUTH_API_FULLPATH = new URL(SERVICELOGIN_AUTH_API, config.apiBase).toString();

export const query = async (dispatch, getState) => {
  let serviceLogin = getState();
  if (serviceLogin.status !== "pending") {
    dispatch({
      type: SERVICELOGIN_QUERY,
      status: "init"
    });

    try {
      let currQS = new URLSearchParams(window.location.search);
      let fid = currQS.get("fid");

      let userData = {
        userinfo: {}
      };
      if (fid) {
        // first login
        let fetchURL = new URL(SERVICELOGIN_AUTH_API, config.apiBase);
        let res = await postJson(fetchURL, { fid });
        let resBody = await res.json();
        assertAPIError(resBody);
        console.log(resBody);
        userData.userinfo = resBody.data.user_info;
        const uaid = resBody.data.uaid;
        if (!uaid) throw new Error("Invalid UAID: ", uaid);
        localStorage.setItem("uaid", uaid);
        currQS.delete("fid");
        history.push(`${window.location.pathname}?${currQS.toString()}`);
      } else {
        // returning login
        let fetchURL = new URL(SERVICELOGIN_QUERY_API, config.apiBase);
        let res = await postJson(fetchURL);
        let resBody = await res.json();
        console.log(resBody);
        let userInfo = get(resBody, "data.user_info");
        if (!userInfo) throw new Error("Not logged in.");
        userData.userinfo = userInfo;
      }

      dispatch({
        type: SERVICELOGIN_QUERY,
        status: "success",
        payload: userData
      });
    } catch (error) {
      dispatch({
        type: SERVICELOGIN_QUERY,
        status: "error",
        payload: error
      });
    }
  }
};

export const login = async (dispatch, getState) => {
  let serviceLogin = getState();
  if (serviceLogin.status !== "pending") {
    dispatch({
      type: SERVICELOGIN_LOGIN,
      status: "init"
    });

    let currQS = new URLSearchParams(window.location.search);
    let fid = currQS.get("fid");
    let fetchURL = new URL(SERVICELOGIN_QUERY_API, config.apiBase);

    try {
      // append fid to login request if fid is in searchParams
      let fetchBody = {};
      if (fid) fetchBody.fid = fid;

      let response = await postJson(fetchURL, fetchBody);
      let result = await response.json();
      console.log(result);

      if (result.result_code === 401 && result.data.redirect) {
        dispatch({
          type: SERVICELOGIN_LOGIN,
          status: "continue",
          payload: result.data.sl
        });

        window.location.href = result.data.redirect;
        return;
      }
      if (response.result_code) throw new Error(response.result_message);
      if (response.error_code) throw new Error(response.error_message);
      dispatch({
        type: SERVICELOGIN_LOGIN,
        status: "success",
        payload: result.data.sl
      });
    } catch (error) {
      dispatch({
        type: SERVICELOGIN_LOGIN,
        status: "error",
        payload: error
      });
    } finally {
      if (fid) {
        // clear fid from queryString on successful login
        currQS.delete("fid");
        history.push(`${window.location.pathname}?${currQS.toString()}`);
      }
    }
  }
};

export const logout = async (dispatch, getState) => {
  let serviceLogin = getState();
  if (serviceLogin.status !== "pending") {
    dispatch({
      type: SERVICELOGIN_LOGOUT,
      status: "init"
    });
    try {
      let response = await fetch(SERVICELOGIN_AUTH_API_FULLPATH, {
        method: "DELETE",
        credentials: "include"
      });
      let result = await response.json();
      console.log("Logout result:", result);
      if (response.result_code) throw new Error(response.result_message);
      if (response.error_code) throw new Error(response.error_message);

      // dispatch({
      //   type: SERVICELOGIN_LOGOUT,
      //   status: "success",
      //   payload: result.data.sl
      // });

      window.location.href = result.data.continue;
      return;
    } catch (error) {
      dispatch({
        type: SERVICELOGIN_LOGOUT,
        status: "error",
        payload: error.message
      });
    }
  }
};
